import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { InvoiceForecastingViewModel } from "../Overview/Invoice/InvoiceForecasting/InvoiceForecastingViewModel";
import { InvoiceForecastingCapacityModel } from "../Overview/Invoice/InvoiceForecasting/InvoiceForecastingModel";
import { InvoiceViewModel } from "../Overview/Invoice/InvoiceViewModel";

class Response {
    public totalInstructedHours: number = 0;
    public totalInstructedValue: number = 0;

    public totalCapacityHours: number = 0;
    public totalCapacityValue: number = 0;

    public totalSentQuoteHours: number = 0;
    public totalSentQuoteValue: number = 0;

    public totalRemainingHolidayHours: number = 0;
    public totalRemainingHolidayValue: number = 0;

    public totalForecastedCapacityHours: number = 0;
    public totalForecastedCapacityValue: number = 0;

    public capacities: CapacityForMonthResponse[] = [];
}

class CapacityForMonthResponse {
    public month: string = "";
    public hours: number = 0;
    public value: number = 0;
    public isActive: boolean = false;
}

export class GetAdminInvoicingForecastEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: InvoiceViewModel;

    constructor(viewModel: InvoiceViewModel) {
        super();

        this.verb(Http.Get);
        this.path(AppUrls.Server.Dashboard.Admin.GetInvoicingForecast);
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            this.viewModel.model.invoiceForecastingModel.fromResponse(response);

            const capacityModels: InvoiceForecastingCapacityModel[] = [];

            response.capacities.forEach((capacity) => {
                const model = new InvoiceForecastingCapacityModel();

                model.month = capacity.month;
                model.hours = capacity.hours;
                model.value = capacity.value;
                model.isActive = capacity.isActive;

                capacityModels.push(model);
            });

            this.viewModel.model.invoiceForecastingModel.capacities.replace(capacityModels);
        });

        return Promise.resolve(response);
    }
}
