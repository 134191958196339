import { isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable, observe } from "mobx";
import { container } from "tsyringe";

import { APIClient, ICommand, RelayCommand, theme } from "Application";
import { AppUrls } from "AppUrls";
import { LookupStore } from "Stores/Domain";
import {
    MODELID_CURRENTQUOTE,
    QUOTESTATUS_APPROVEDTYPE,
    QUOTESTATUS_DRAFTTYPE,
    QUOTESTATUS_NOTYPE,
    QUOTESTATUS_PENDINGAPPROVALTYPE,
    QUOTESTATUS_SENTTYPE,
} from "Views/Shared/Constants";
import { NoteViewModel } from "Views/Shared/Note/NoteViewModel";
import { RevisionViewModel } from "Views/Shared/Revisions/RevisionViewModel";
import { GETQuoteWithRelatedByIdEndpoint } from "./Endpoints/GETQuoteWithRelatedByIdEndpoint";
import { GETQuoteRelatedEndpoint } from "./Endpoints/GETQuoteRelatedEndpoint";
import { POSTSaveQuoteAsDraftEndpoint } from "./Endpoints/POSTSaveQuoteAsDraftEndpoint";
import { POSTSaveQuoteAsPendingApprovalEndpoint } from "./Endpoints/POSTSaveQuoteAsPendingApprovalEndpoint";
import { POSTSaveQuoteAsApprovedEndpoint } from "./Endpoints/POSTSaveQuoteAsApprovedEndpoint";
import { POSTSaveQuoteAsRejectedEndpoint } from "./Endpoints/POSTSaveQuoteAsRejectedEndpoint";
import { POSTSaveQuoteAsProjectEndpoint } from "./Endpoints/POSTSaveQuoteAsProjectEndpoint";
import { POSTSaveQuoteAsLostEndpoint } from "./Endpoints/POSTSaveQuoteAsLostEndpoint";
import { POSTSaveQuoteAsRevisedEndpoint } from "./Endpoints/POSTSaveQuoteAsRevisedEndpoint";
import { POSTSaveQuoteAsSentEndpoint } from "./Endpoints/POSTSaveQuoteAsSentEndpoint";
import { POSTSaveQuoteNoteEndpoint } from "./Endpoints/POSTSaveQuoteNoteEndpoint";
import { GETQuoteRelatedByCustomerIdEndpoint } from "./Endpoints/GETQuoteRelatedByCustomerIdEndpoint";
import { POSTDeleteQuoteEndpoint } from "./Endpoints/POSTDeleteQuoteEndpoint";
import { POSTSaveQuoteLetterEndpoint } from "./Endpoints/POSTSaveQuoteLetterEndpoint";
import { POSTSaveQuoteTermsAndConditionsEndpoint } from "./Endpoints/POSTSaveQuoteTermsAndConditionsEndpoint";
import { POSTSaveQuoteTasksAndCostsEndpoint } from "./Endpoints/POSTSaveQuoteTasksAndCostsEndpoint";
import { POSTGenerateQuoteEndpoint } from "./Endpoints/POSTGenerateQuoteEndpoint";
import { GETQuickQuoteRelatedByCustomerIdEndpoint } from "./Endpoints/GETQuickQuoteRelatedByCustomerIdEndpoint";
import { GETQuoteLetterDocumentByIdEndpoint } from "../Endpoints/GETQuoteLetterDocumentByIdEndpoint";
import { GETQuoteTasksAndCostsDocumentByIdEndpoint } from "../Endpoints/GETQuoteTasksAndCostsDocumentByIdEndpoint";
import { GETQuoteTermsAndConditionsDocumentByIdEndpoint } from "../Endpoints/GETQuoteTermsAndConditionsDocumentByIdEndpoint";
import { QuoteFormViewModel } from "./Form/QuoteFormViewModel";
import { QuoteContainerModel } from "./QuoteContainerModel";
import { IFormQuoteContainerViewModel } from "./IFormQuoteContainerViewModel";
import { POSTRevertLostQuoteEndpoint } from "./Endpoints/POSTRevertLostQuoteEndpoint";
import { AutoSaveStore } from "Stores/Domain/AutoSaveStore";
import { set } from "date-fns";

export class QuoteContainerViewModel extends ViewModelBase<QuoteContainerModel> implements IFormQuoteContainerViewModel {
    public apiClient = new APIClient();
    private lookupStore = container.resolve(LookupStore);
    public quoteFormViewModel: QuoteFormViewModel;
    public activeRevisionViewModel: RevisionViewModel | null = null;
    public revisionViewModels = observable<RevisionViewModel>([]);

    // APM - Autosave is not safe to use and has been disabled. Please see #16850.
    /*
    private autoSaveStore = container.resolve(AutoSaveStore);
    public autoSaveQuoteAsDraftIntervalId: NodeJS.Timer | null = null;
    public autoSaveQuoteAsPendingApprovalIntervalId: NodeJS.Timer | null = null;
    public autoSaveInterval: number = 5 * 60 * 1000; // 5 minutes
    */

    //region modals
    public showQuickQuoteWarningModal: boolean = false;

    constructor() {
        super(new QuoteContainerModel());

        makeObservable(this, {
            // Observables
            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            /*
            autoSaveQuoteAsDraftIntervalId: observable,
            autoSaveQuoteAsPendingApprovalIntervalId: observable,
            */
            showQuickQuoteWarningModal: observable,
            revisionViewModels: observable,
            activeRevisionViewModel: observable,

            // Action
            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            /*
            autoSaveQuoteAsDraft: action,
            autoSaveQuoteAsPendingApproval: action,
            clearAutoSaveQuoteAsDraft: action,
            clearAutoSaveQuoteAsPendingApproval: action,
            autoSaveQuote: action,
            */
            setShowQuickQuoteWarningModal: action,

            // Computed
            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            /*
            isAutoSaving: computed,
            */
            canDisplayRevisionNavigation: computed,
            canDisplayQuoteStatus: computed,
            quoteStatusDisplayName: computed,
            quoteStatusType: computed,
            quoteStatusBackgroundColor: computed,
            displayName: computed,
            displayProjectName: computed,
            canShowQuickQuoteWarningModal: computed,
            canDisplaySaveQuoteAsDraftCommand: computed,
            canDisplayGenerateQuoteCommand: computed,
            canDisplaySaveQuoteAsPendingApprovalCommand: computed,
            canDisplayDeleteQuoteCommand: computed,
            canDisplayApproveQuoteCommand: computed,
            canDisplayRejectQuoteCommand: computed,
            canDisplayRevertLostQuoteCommand: computed,
            canDisplayDownloadAndSendQuoteCommand: computed,
            canDisplayPreviewQuoteLetterDocumentCommand: computed,
            canDisplayPreviewQuoteTermsAndConditionsDocumentCommand: computed,
            canDisplayPreviewQuoteTasksAndCostsDocumentCommand: computed,
            canDisplaySetQuoteLeadCommand: computed,
            canDisplayConvertQuoteToProjectCommand: computed,
            canDisplayReviseQuoteCommand: computed,
            canDisplayDownloadPDFFileCommand: computed,
            canDisplayMarkQuoteAsLostCommand: computed,
            canDisplaySaveQuoteNoteCommand: computed,
        });

        this.quoteFormViewModel = new QuoteFormViewModel(this.model.quoteForm, this);
    }

    //region modals
    public setShowQuickQuoteWarningModal = (showQuickQuoteWarningModal: boolean) => {
        this.showQuickQuoteWarningModal = showQuickQuoteWarningModal;
    };

    public get canShowQuickQuoteWarningModal() {
        return this.showQuickQuoteWarningModal;
    }

    //endregion

    public initialize = async (quoteId: string | undefined, customerId: string | undefined, isQuickQuote: string | undefined, projectId: string | undefined) => {
        // Server Actions
        switch (true) {
            case customerId !== undefined && isQuickQuote !== undefined && isQuickQuote === "false":
                await this.apiClient.sendAsync(new GETQuoteRelatedByCustomerIdEndpoint(customerId!, this));
                break;

            case customerId !== undefined && isQuickQuote !== undefined && isQuickQuote === "true":
                await this.apiClient.sendAsync(new GETQuickQuoteRelatedByCustomerIdEndpoint(customerId!, this));
                break;

            case !quoteId:
                await this.apiClient.sendAsync(new GETQuoteRelatedEndpoint(this));
                break;

            default:
                await this.apiClient.sendAsync(new GETQuoteWithRelatedByIdEndpoint(quoteId!, this));
                break;
        }

        if (projectId) {
            console.log(this.quoteFormViewModel.quoteViewModel);
            this.quoteFormViewModel.quoteViewModel._isLinkedToProject = !!projectId;
            this.quoteFormViewModel.quoteViewModel.setValue("projectId", projectId);
        }

        // APM - Autosave is not safe to use and has been disabled. Please see #16850.
        // this.initializeAutoSave();
    };

    //REGION AUTOSAVE

    // APM - Autosave is not safe to use and has been disabled. Please see #16850.
    /*
    public get isAutoSaving() {
        return this.autoSaveStore.getAutoSave;
    }

    public initializeAutoSave = () => {
        // Ensure any existing auto-save features are disposed.
        this.clearAutoSave();

        // Create auto-save features based on the status of the quote.
        if (!(this.quoteFormViewModel.model.isProject || this.quoteFormViewModel.model.isLost)) {
            switch (this.quoteStatusType) {
                case QUOTESTATUS_NOTYPE:
                case QUOTESTATUS_DRAFTTYPE:
                    this.autoSaveQuoteAsDraft();
                    break;

                case QUOTESTATUS_PENDINGAPPROVALTYPE:
                    this.autoSaveQuoteAsPendingApproval();
                    break;

                default:
                    // Do nothing.
                    break;
            }
        }
    };

    public autoSaveQuote = async (callback: () => Promise<void>) => {
        this.autoSaveStore.setAutoSave(true);

        await callback();

        this.autoSaveStore.setAutoSave(false);
    };

    public autoSaveQuoteAsDraft = () => {
        if (this.apiClient.IsBusy) return;

        const callback = async () => {
            if (this.model.quoteForm.id && this.canDisplaySaveQuoteAsDraftCommand) {
                this.setSaveQuoteStatusType(QUOTESTATUS_DRAFTTYPE);

                if (this.canSubmitForm) {
                    await this.apiClient.sendAsync(new POSTSaveQuoteAsDraftEndpoint(this), this.getModel);
                }

                // Now revert the validation rules having processed the command.
                this.setSaveQuoteStatusType(null);
            }
        };

        this.autoSaveQuoteAsDraftIntervalId = setInterval(() => this.autoSaveQuote(callback), this.autoSaveInterval);
    };

    public autoSaveQuoteAsPendingApproval = () => {
        const callback = async () => {
            if (!this.apiClient.IsBusy && this.canDisplaySaveQuoteAsPendingApprovalCommand) {
                if (this.canSubmitForm) {
                    await this.apiClient.sendAsync(new POSTSaveQuoteAsPendingApprovalEndpoint(this), this.getModel);
                }
            }
        };

        this.autoSaveQuoteAsDraftIntervalId = setInterval(() => this.autoSaveQuote(callback), this.autoSaveInterval);
    };

    public clearAutoSave = () => {
        this.clearAutoSaveQuoteAsDraft();
        this.clearAutoSaveQuoteAsPendingApproval();
    };

    public clearAutoSaveQuoteAsDraft = () => {
        if (this.autoSaveQuoteAsDraftIntervalId) {
            clearInterval(this.autoSaveQuoteAsDraftIntervalId);
            this.autoSaveQuoteAsDraftIntervalId = null;
        }
    };

    public clearAutoSaveQuoteAsPendingApproval = () => {
        if (this.autoSaveQuoteAsPendingApprovalIntervalId) {
            clearInterval(this.autoSaveQuoteAsPendingApprovalIntervalId);
            this.autoSaveQuoteAsPendingApprovalIntervalId = null;
        }
    };
    */

    //ENDREGION AUTOSAVE

    /**
     * Disposes any resources. Call this from your view.
     */
    public dispose = (): void => {
        this.quoteFormViewModel.dispose();
        this.revisionsObserverDispose?.();

        // APM - Autosave is not safe to use and has been disabled. Please see #16850.
        /*
        this.clearAutoSave();
        */
    };

    // #region Properties

    /**
     * Returns if revision navigation can be displayed.
     */
    public get canDisplayRevisionNavigation() {
        return this.revisionViewModels.length > 1;
    }

    /**
     * Returns if the quote status can be displayed.
     */
    public get canDisplayQuoteStatus() {
        return this.model.quoteForm.isLost || !isEmptyOrWhitespace(this.model.quoteForm.quoteStatusId);
    }

    /**
     * Returns the quote status display name.
     */
    public get quoteStatusDisplayName() {
        return this.model.quoteForm.isLost
            ? "LOST"
            : this.model.quoteForm.isProject
            ? "PROJECT"
            : this.lookupStore.quoteStatuses.find((qs) => qs.id === this.model.quoteForm.quoteStatusId)?.name?.toLocaleUpperCase() ?? "";
    }

    /**
     * Returns the quote status type.
     */
    public get quoteStatusType() {
        return this.lookupStore.quoteStatuses.find((qs) => qs.id === this.model.quoteForm.quoteStatusId)?.type ?? QUOTESTATUS_NOTYPE;
    }

    /**
     * Returns the quote status color.
     */
    public get quoteStatusBackgroundColor() {
        return this.model.quoteForm.isLost
            ? theme.palette.common.black
            : this.model.quoteForm.isProject
            ? theme.palette.common.quarternary
            : this.lookupStore.quoteStatuses.find((qs) => qs.id === this.model.quoteForm.quoteStatusId)?.foregroundColor ?? "";
    }

    /**
     * Returns the display name of the quote. We are using the revision current quote title here,
     * otherwise this would change as the quote title field was edited.
     */
    public get displayName() {
        return isEmptyOrWhitespace(this.model.quoteForm.id)
            ? this.model.quoteForm.isQuickQuote
                ? "New quick quote"
                : "New quote"
            : isNullOrUndefined(this.model.quoteForm.revision)
            ? `${this.model.quoteForm.reference}: ${this.model.revisions.find((r) => r.id === MODELID_CURRENTQUOTE)?.title ?? "Unknown"}`
            : `${this.model.quoteForm.reference}: ${this.model.revisions.find((r) => r.id === MODELID_CURRENTQUOTE)?.title ?? "Unknown"} - Revision ${
                  this.model.quoteForm.revision
              }`;
    }

    public get displayProjectName() {
        let retVal = "";
        const chosenProjectId = this.quoteFormViewModel.quoteViewModel.model.projectId;

        if (chosenProjectId) {
            let chosenProject = this.quoteFormViewModel.quoteViewModel.getProjectsList.find((project) => project.id === chosenProjectId);
            retVal = chosenProject?.title != undefined ? chosenProject?.title : "";
        }

        return retVal;
    }

    public get isQuickQuote() {
        return this.model.quoteForm.isQuickQuote;
    }

    public get isLinkedToAProject() {
        return this.quoteFormViewModel.quoteViewModel.model.projectId != null;
    }

    public get isQuoteNew() {
        return this.quoteFormViewModel.model.id == null;
    }

    // #endregion Properties

    // #region Commands

    public navigateToQuotesCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Quotes.Table, { activeTab: "quotes" });
    });

    public navigateToRevision = new RelayCommand((vm: RevisionViewModel) => {
        this.activeRevisionViewModel = vm;

        // SIDE-EFFECT - Update active states of the revisions.
        for (const revisionViewModel of this.revisionViewModels) {
            revisionViewModel.setActive(revisionViewModel.model.KEY === this.activeRevisionViewModel?.model?.KEY);
        }
    });

    public get canDisplaySaveQuoteAsDraftCommand() {
        return (
            !this.quoteFormViewModel.model.isProject &&
            !this.quoteFormViewModel.model.isLost &&
            (this.quoteStatusType === QUOTESTATUS_NOTYPE || this.quoteStatusType === QUOTESTATUS_DRAFTTYPE)
        );
    }

    private setSaveQuoteStatusType = (quoteStatusType: string | null) => {
        this.quoteFormViewModel.quoteViewModel.setSaveQuoteStatusType(quoteStatusType);
        this.quoteFormViewModel.letterViewModel.setSaveQuoteStatusType(quoteStatusType);
        this.quoteFormViewModel.termsAndConditionsViewModel.setSaveQuoteStatusType(quoteStatusType);
    };

    public get checkCanShowQuickQuoteWarningModal() {
        return this.isQuickQuote && !this.isLinkedToAProject && this.isQuoteNew;
    }

    public saveQuoteAsDraftCommand = new RelayCommand(() => {
        if (this.checkCanShowQuickQuoteWarningModal) {
            this.setShowQuickQuoteWarningModal(true);
        } else if (this.confirmSaveQuoteAsDraftCommand.canExecute()) {
            this.confirmSaveQuoteAsDraftCommand.execute();
        }
    });

    public dismissSaveQuoteAsDraftCommand = new RelayCommand(() => {
        this.setShowQuickQuoteWarningModal(false);
    });

    public confirmSaveQuoteAsDraftCommand = new RelayCommand(
        async () => {
            if (this.showQuickQuoteWarningModal) {
                this.setShowQuickQuoteWarningModal(false);
            }
            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.clearAutoSave();

            this.setSaveQuoteStatusType(QUOTESTATUS_DRAFTTYPE);

            //determine if the quote saved is new or existing before the upsert
            const quoteId: string | null = this.model.quoteForm.id;

            if (this.canSubmitForm) {
                await this.apiClient.sendAsync(new POSTSaveQuoteAsDraftEndpoint(this), this.getModel);
            }

            // if quote is new push to the correct url otherwise do nothing
            if (quoteId === null) {
                this.history.replace(AppUrls.Client.Quotes.Edit.replace(":id", this.model.quoteForm.id!));
            }

            // Now revert the validation rules having processed the command.
            this.setSaveQuoteStatusType(null);

            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.initializeAutoSave();
        },
        () => !this.apiClient.IsBusy && this.canDisplaySaveQuoteAsDraftCommand,
    );

    public get canDisplayGenerateQuoteCommand() {
        return !this.quoteFormViewModel.model.isProject && !this.quoteFormViewModel.model.isLost && this.quoteStatusType === QUOTESTATUS_DRAFTTYPE;
    }

    public generateQuoteCommand = new RelayCommand(
        async () => {
            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.clearAutoSave();

            if (this.canSubmitForm) {
                await this.apiClient.sendAsync(new POSTGenerateQuoteEndpoint(this), this.getModel);
            }

            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.initializeAutoSave();
        },
        () => !this.apiClient.IsBusy && this.canDisplayGenerateQuoteCommand,
    );

    public get canDisplaySaveQuoteAsPendingApprovalCommand() {
        return !this.quoteFormViewModel.model.isProject && !this.quoteFormViewModel.model.isLost && this.quoteStatusType === QUOTESTATUS_PENDINGAPPROVALTYPE;
    }

    public saveQuoteAsPendingApprovalCommand = new RelayCommand(
        async () => {
            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.clearAutoSave();

            if (this.canSubmitForm) {
                await this.apiClient.sendAsync(new POSTSaveQuoteAsPendingApprovalEndpoint(this), this.getModel);
            }

            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            //this.initializeAutoSave();
        },
        () => !this.apiClient.IsBusy && this.canDisplaySaveQuoteAsPendingApprovalCommand,
    );

    public get canDisplayDeleteQuoteCommand() {
        return true;
    }

    public deleteQuoteCommand = new RelayCommand(
        async () => {
            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.clearAutoSave();

            this.apiClient.sendAsync(new POSTDeleteQuoteEndpoint(this), this.getModel);

            // No need to initialize auto-save. We are not remaining on this page.
        },
        () => {
            return !this.apiClient.IsBusy && this.canDisplayDeleteQuoteCommand;
        },
    );

    public get canDisplayApproveQuoteCommand() {
        return !this.quoteFormViewModel.model.isProject && !this.quoteFormViewModel.model.isLost && this.quoteStatusType === QUOTESTATUS_PENDINGAPPROVALTYPE;
    }

    public approveQuoteCommand = new RelayCommand(
        async () => {
            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.clearAutoSave();

            if (this.canSubmitForm) {
                await this.apiClient.sendAsync(new POSTSaveQuoteAsApprovedEndpoint(this), this.getModel);
            }

            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.initializeAutoSave();
        },
        () => {
            return !this.apiClient.IsBusy && this.canDisplayApproveQuoteCommand;
        },
    );

    public get canDisplayRejectQuoteCommand() {
        return !this.quoteFormViewModel.model.isProject && !this.quoteFormViewModel.model.isLost && this.quoteStatusType === QUOTESTATUS_PENDINGAPPROVALTYPE;
    }

    public rejectQuoteCommand = new RelayCommand(
        async () => {
            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.clearAutoSave();

            if (this.canSubmitForm) {
                await this.apiClient.sendAsync(new POSTSaveQuoteAsRejectedEndpoint(this), this.getModel);
            }

            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.initializeAutoSave();
        },
        () => {
            return !this.apiClient.IsBusy && this.canDisplayRejectQuoteCommand;
        },
    );

    //Revert lost quote
    public get canDisplayRevertLostQuoteCommand() {
        return !this.quoteFormViewModel.model.isProject && this.quoteFormViewModel.model.isLost;
    }

    public revertLostQuoteCommand = new RelayCommand(
        async () => {
            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.clearAutoSave();

            if (this.canSubmitForm) {
                await this.apiClient.sendAsync(new POSTRevertLostQuoteEndpoint(this), this.getModel);
            }

            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.initializeAutoSave();
        },
        () => {
            return !this.apiClient.IsBusy && this.canDisplayRevertLostQuoteCommand;
        },
    );

    public get canDisplayDownloadAndSendQuoteCommand() {
        return !this.quoteFormViewModel.model.isProject && !this.quoteFormViewModel.model.isLost && this.quoteStatusType === QUOTESTATUS_APPROVEDTYPE;
    }

    public downloadAndSendQuoteCommand = new RelayCommand(
        async () => {
            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.clearAutoSave();

            if (this.canSubmitForm) {
                await this.apiClient.sendAsync(new POSTSaveQuoteAsSentEndpoint(this), this.getModel);

                // Only download documents if the request was successful.
                if (this.apiClient.IsRequestSuccessful) {
                    if (!this.quoteFormViewModel.model.isQuickQuote) {
                        await this.apiClient.sendAsync(new GETQuoteLetterDocumentByIdEndpoint(this.model.quoteForm.id!));
                        await this.apiClient.sendAsync(new GETQuoteTermsAndConditionsDocumentByIdEndpoint(this.model.quoteForm.id!));
                    }

                    await this.apiClient.sendAsync(new GETQuoteTasksAndCostsDocumentByIdEndpoint(this.model.quoteForm.id!));
                }
            }

            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.initializeAutoSave();
        },
        () => !this.apiClient.IsBusy && this.canDisplayDownloadAndSendQuoteCommand,
    );

    public get canDisplayPreviewQuoteLetterDocumentCommand() {
        return (
            !isEmptyOrWhitespace(this.quoteFormViewModel.model.id) &&
            !isEmptyOrWhitespace(this.quoteFormViewModel.model.letterModel.letterTemplateId) &&
            !isEmptyOrWhitespace(this.quoteFormViewModel.model.letterModel.letterDetails)
        );
    }

    public previewQuoteLetterDocumentCommand = new RelayCommand(
        async () => {
            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.clearAutoSave();

            if (this.quoteFormViewModel.letterViewModel.isModelValid()) {
                await this.apiClient.sendAsync(new POSTSaveQuoteLetterEndpoint(this), this.getModel);
                await this.apiClient.sendAsync(new GETQuoteLetterDocumentByIdEndpoint(this.model.quoteForm.id!, true));
            }

            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.initializeAutoSave();
        },
        () => !this.apiClient.IsBusy && this.canDisplayPreviewQuoteLetterDocumentCommand,
    );

    public get canDisplayPreviewQuoteTermsAndConditionsDocumentCommand() {
        return (
            !isEmptyOrWhitespace(this.quoteFormViewModel.model.id) &&
            !isEmptyOrWhitespace(this.quoteFormViewModel.model.termsAndConditionsModel.termAndConditionId) &&
            !isEmptyOrWhitespace(this.quoteFormViewModel.model.termsAndConditionsModel.termsAndConditionsDetails)
        );
    }

    public previewQuoteTermsAndConditionsDocumentCommand = new RelayCommand(
        async () => {
            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.clearAutoSave();

            if (this.quoteFormViewModel.termsAndConditionsViewModel.isModelValid()) {
                await this.apiClient.sendAsync(new POSTSaveQuoteTermsAndConditionsEndpoint(this), this.getModel);
                await this.apiClient.sendAsync(new GETQuoteTermsAndConditionsDocumentByIdEndpoint(this.model.quoteForm.id!, true));
            }

            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.initializeAutoSave();
        },
        () => !this.apiClient.IsBusy && this.canDisplayPreviewQuoteTermsAndConditionsDocumentCommand,
    );

    public get canDisplayPreviewQuoteTasksAndCostsDocumentCommand() {
        return !isEmptyOrWhitespace(this.quoteFormViewModel.model.id) && this.quoteFormViewModel.model.servicesModel.taskGroups.length > 0;
    }

    public previewQuoteTasksAndCostsDocumentCommand = new RelayCommand(
        async () => {
            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.clearAutoSave();

            if (this.quoteFormViewModel.servicesViewModel.isModelValid()) {
                await this.apiClient.sendAsync(new POSTSaveQuoteTasksAndCostsEndpoint(this), this.getModel);
                await this.apiClient.sendAsync(new GETQuoteTasksAndCostsDocumentByIdEndpoint(this.model.quoteForm.id!, true));
            }

            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.initializeAutoSave();
        },
        () => !this.apiClient.IsBusy && this.canDisplayPreviewQuoteTasksAndCostsDocumentCommand,
    );

    public get canDisplaySetQuoteLeadCommand() {
        return true;
    }

    public get canDisplayConvertQuoteToProjectCommand() {
        return !this.quoteFormViewModel.model.isProject && !this.quoteFormViewModel.model.isLost && this.quoteStatusType === QUOTESTATUS_SENTTYPE;
    }

    public convertQuoteToProjectCommand = new RelayCommand(
        () => {
            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.clearAutoSave();

            if (this.canSubmitForm) {
                this.apiClient.sendAsync(new POSTSaveQuoteAsProjectEndpoint(this), this.getModel);
            }

            // No need to initialize auto-save. We are not remaining on this page.
        },
        () => !this.apiClient.IsBusy && this.canDisplayConvertQuoteToProjectCommand,
    );

    public get canDisplayReviseQuoteCommand() {
        return !this.quoteFormViewModel.model.isProject && (this.quoteFormViewModel.model.isLost || this.quoteStatusType === QUOTESTATUS_SENTTYPE);
    }

    public reviseQuoteCommand = new RelayCommand(
        async () => {
            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.clearAutoSave();

            if (this.canSubmitForm) {
                await this.apiClient.sendAsync(new POSTSaveQuoteAsRevisedEndpoint(this), this.getModel);
            }

            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.initializeAutoSave();
        },
        () => !this.apiClient.IsBusy && this.canDisplayReviseQuoteCommand,
    );

    public get canDisplayDownloadPDFFileCommand() {
        return !this.quoteFormViewModel.model.isProject && !this.quoteFormViewModel.model.isLost && this.quoteStatusType === QUOTESTATUS_SENTTYPE;
    }

    public downloadPDFFileCommand = new RelayCommand(
        async () => {
            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.clearAutoSave();

            if (!this.quoteFormViewModel.model.isQuickQuote) {
                await this.apiClient.sendAsync(new GETQuoteLetterDocumentByIdEndpoint(this.model.quoteForm.id!));
                await this.apiClient.sendAsync(new GETQuoteTermsAndConditionsDocumentByIdEndpoint(this.model.quoteForm.id!));
            }

            await this.apiClient.sendAsync(new GETQuoteTasksAndCostsDocumentByIdEndpoint(this.model.quoteForm.id!));

            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.initializeAutoSave();
        },
        () => !this.apiClient.IsBusy && this.canDisplayDownloadPDFFileCommand,
    );

    public get canDisplayMarkQuoteAsLostCommand() {
        return !this.quoteFormViewModel.model.isProject && !this.quoteFormViewModel.model.isLost && this.quoteStatusType === QUOTESTATUS_SENTTYPE;
    }

    public markQuoteAsLostCommand = new RelayCommand(
        async () => {
            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.clearAutoSave();

            if (this.canSubmitForm) {
                await this.apiClient.sendAsync(new POSTSaveQuoteAsLostEndpoint(this), this.getModel);
            }

            // APM - Autosave is not safe to use and has been disabled. Please see #16850.
            // this.initializeAutoSave();
        },
        () => !this.apiClient.IsBusy && this.canDisplayMarkQuoteAsLostCommand,
    );

    public get canDisplaySaveQuoteNoteCommand() {
        return true;
    }

    public saveQuoteNoteCommand = new RelayCommand(
        (noteViewModel: NoteViewModel) => {
            this.apiClient.sendAsync(new POSTSaveQuoteNoteEndpoint(this.quoteFormViewModel, noteViewModel), noteViewModel.editNoteViewModel?.model);
        },
        () => !this.apiClient.IsBusy && this.canDisplaySaveQuoteNoteCommand,
    );

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    // #endregion Comands

    // #region Supporting

    /**
     * An observer to listen to changes in the revision collection. Use this to create or remove
     * revision viewmodels in response to changes in the revision model collection.
     */
    private revisionsObserverDispose = observe(this.model.revisions, (revisionChanges: any) => {
        for (const addedRevision of revisionChanges.added) {
            const revisionViewModelToAdd = new RevisionViewModel(addedRevision, this.navigateToRevision);

            this.revisionViewModels.push(revisionViewModelToAdd);

            // SIDE-EFFECT - If the new viewmodel is the current quote, make this
            // the selected revision.
            if (revisionViewModelToAdd.model.id === MODELID_CURRENTQUOTE) {
                this.navigateToRevision.execute(revisionViewModelToAdd);
            }
        }

        for (const removedRevision of revisionChanges.removed) {
            const revisionViewModelToRemove = this.revisionViewModels.find((vm) => vm.model.KEY === removedRevision.KEY);

            if (revisionViewModelToRemove) {
                this.revisionViewModels.remove(revisionViewModelToRemove);
            }
        }
    });

    public get canSubmitForm(): boolean {
        const isFormValid = this.quoteFormViewModel.isModelValid();
        const isQuoteValid = this.quoteFormViewModel.quoteViewModel.isModelValid();
        const isCustomerValid = this.quoteFormViewModel.customerViewModel.isModelValid();
        const isSevicesValid = this.quoteFormViewModel.servicesViewModel.canSubmitForm;
        const isNotesValid = this.quoteFormViewModel.notesViewModel.isModelValid();

        const isLetterValid = this.quoteFormViewModel.letterViewModel.isModelValid();
        const isTermsAndConditionsValid = this.quoteFormViewModel.termsAndConditionsViewModel.isModelValid();

        return isFormValid && isQuoteValid && isCustomerValid && isLetterValid && isSevicesValid && isTermsAndConditionsValid && isNotesValid;
    }

    // #endregion Supporting
}
