import styled from "@emotion/styled";
import { IButtonBaseProps, ICommand, RelayCommand, ThemedButton, themePaletteOptions } from "Application";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { SpaceProps } from "styled-system";
import React from "react";

//#region styledComponents
const Container = styled.div`
    position: relative;
    width: 100%;
`;

const OptionsContainer = styled.div<{ direction: Direction }>`
    position: absolute;
    top: ${({ direction }) => (direction === "up" ? "calc(-100% - 30px - 15px)" : "auto")};
    bottom: ${({ direction }) => (direction === "down" ? "100%" : "auto")};
    left: ${({ direction }) => (direction === "left" ? "-100%" : "auto")};
    right: ${({ direction }) => (direction === "right" ? "100%" : "auto")};
`;

const OptionContainer = styled.div`
    width: 100%;
`;

//##endregion styledComponents

type Direction = "up" | "down" | "left" | "right";

interface IThemedButtonMultiOptionProps {
    // The button display name.
    displayName: string;
    // The direction in which to display the options relative to the button.
    direction: Direction;
    // The options to display in the button.
    options: IThemedButtonMultiOptionItemProps[];
    // Palette color of the button.
    paletteColor?: themePaletteOptions;
}

export interface IThemedButtonMultiOptionItemProps {
    displayName: string;
    command: ICommand;
    paletteColor?: themePaletteOptions;
}

export const ThemedButtonMultiOption = observer((props: IThemedButtonMultiOptionProps) => {
    const [showOptions, setShowOptions] = useState(false);

    const command: ICommand = new RelayCommand(() => {
        setShowOptions(!showOptions);
    });

    return (
        <Container>
            <ThemedButton styles={{ root: { width: "100%" } }} displayName={props.displayName} paletteColor={props.paletteColor} command={command} />
            {showOptions && (
                <>
                    <OptionsContainer direction={props.direction}>
                        {props.options.map((option, index) => {
                            const optionCommand: ICommand = new RelayCommand(() => {
                                setShowOptions(false);
                                option.command.execute();
                            });

                            return (
                                <OptionContainer key={index}>
                                    <ThemedButton displayName={option.displayName} paletteColor={option.paletteColor} command={optionCommand} />
                                </OptionContainer>
                            );
                        })}
                    </OptionsContainer>
                </>
            )}
        </Container>
    );
});
