import { formatCurrency, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { APIClient, formatDate, RelayCommand } from "Application";
import { AppUrls } from "AppUrls";
import { AccountStore, LookupStore } from "Stores/Domain";
import { DomainStore } from "Stores/Domain/DomainStore";
import { CONTACTTYPE_COMMERCIALTYPE } from "Views/Shared/Constants";
import { FilesViewModel } from "Views/Shared/Files/FilesViewModel";
import { CustomerContact, CustomerDetailModel, Enquiry, Project, Quote } from "./CustomerDetailModel";
import { DeleteCustomerEndpoint } from "./DeleteCustomerEndpoint";
import { GetCustomerDetailsEndpoint } from "./GETCustomerDetailsEndpoint";

export default class CustomerDetailViewModel extends ViewModelBase<CustomerDetailModel> {
    public apiClient = new APIClient();
    public filesViewModel = new FilesViewModel();

    public deletePopup: boolean = false;
    public lookupStore = container.resolve(LookupStore);
    public domainStore = container.resolve(DomainStore);
    public accountStore = container.resolve(AccountStore);

    constructor(customerId: string) {
        super(new CustomerDetailModel());

        makeObservable(this, {
            // Observables

            deletePopup: observable,
            // Computed
            canDisplayLost: computed,
            canDisplayCustomerContacts: computed,
            canDisplayClientNotes: computed,
            canDisplayEnquiries: computed,
            canDisplayQuotes: computed,
            canDisplayProjects: computed,
            canDisplayCompleted: computed,
            canDisplayCustomerContactNumber1: computed,
            canDisplayCustomerContactNumber2: computed,
            canDisplayCustomerEmailAddress: computed,
            canDisplayCustomerAddress: computed,
            displayName: computed,
            customerAddress: computed,
        });

        // Server Actions.
        this.apiClient.sendAsync(new GetCustomerDetailsEndpoint(customerId, this));
    }

    // #region User Roles

    public get isAdmin() {
        return this.lookupStore.AccountStore.isInRole("admin");
    }

    public get isBusinessSupport() {
        return this.lookupStore.AccountStore.isInRole("businesssupport");
    }

    public get isSeniorAssociate() {
        return this.lookupStore.AccountStore.isInRole("seniorassociate");
    }

    public get isArchitect() {
        return this.lookupStore.AccountStore.isInRole("architect");
    }

    // #endregion User Roles

    // #region Display Permissions

    public get showNewEnquiriesButton() {
        return this.isAdmin || this.isSeniorAssociate || this.isBusinessSupport;
    }

    public get showNewQuoteButton() {
        return this.isAdmin || this.isSeniorAssociate || this.isBusinessSupport;
    }

    // #endregion Display Permissions

    // #region Properties

    /**
     * Returns the display name of the customer.
     */
    public get displayName() {
        return this.model.customer.contactTypeId === this.lookupStore.getContactTypeIdByType(CONTACTTYPE_COMMERCIALTYPE)
            ? this.model.customer.businessName
            : [this.model.customer.prefixName, this.model.customer.firstName, this.model.customer.lastName].filter((item) => !isEmptyOrWhitespace(item)).join(" ");
    }

    public get customerAddress() {
        return [this.model.customer.address1, this.model.customer.address2, this.model.customer.address3, this.model.customer.city, this.model.customer.postcode]
            .filter((item) => !isEmptyOrWhitespace(item))
            .join(", ");
    }

    public get clientNote() {
        return this.model.customer.clientNote;
    }

    public get canDisplayCustomerContactNumber1() {
        return !isEmptyOrWhitespace(this.model.customer.contactNumber1);
    }

    public get canDisplayCustomerContactNumber2() {
        return !isEmptyOrWhitespace(this.model.customer.contactNumber2);
    }

    public get canDisplayCustomerEmailAddress() {
        return !isEmptyOrWhitespace(this.model.customer.emailAddress);
    }

    public get canDisplayCustomerEmailAddress2() {
        return !isEmptyOrWhitespace(this.model.customer.emailAddress2);
    }

    public get canDisplayCustomerAddress() {
        return !isEmptyOrWhitespace(this.customerAddress);
    }

    public get canDisplayCustomerContacts() {
        return this.model.customerContacts.length > 0;
    }

    public canDisplayCustomerContactContactNumber1(customerContact: CustomerContact) {
        return !isEmptyOrWhitespace(customerContact.contactNumber1);
    }

    public canDisplayCustomerContactContactNumber2(customerContact: CustomerContact) {
        return !isEmptyOrWhitespace(customerContact.contactNumber2);
    }

    public canDisplayCustomerContactEmailAddress(customerContact: CustomerContact) {
        return !isEmptyOrWhitespace(customerContact.emailAddress);
    }

    public canDisplayCustomerContactAddress(customerContact: CustomerContact) {
        return (
            !customerContact.sameAsCustomerAddress &&
            (!isEmptyOrWhitespace(customerContact.address1) ||
                !isEmptyOrWhitespace(customerContact.address2) ||
                !isEmptyOrWhitespace(customerContact.address3) ||
                !isEmptyOrWhitespace(customerContact.city) ||
                !isEmptyOrWhitespace(customerContact.postcode))
        );
    }

    public get canDisplayClientNotes() {
        return !isEmptyOrWhitespace(this.model.customer.clientNote) || this.filesViewModel.fileViewModels.length > 0;
    }

    public get canDisplayEnquiries() {
        return this.model.enquiries.length > 0;
    }

    public get canDisplayQuotes() {
        return this.model.quotes.length > 0;
    }

    public get canDisplayLost() {
        return this.model.lostEnquiries.length > 0 || this.model.lostQuotes.length > 0;
    }

    public get canDisplayProjects() {
        return this.model.projects.length > 0;
    }

    public get canDisplayCompleted() {
        return this.model.completedProjects.length > 0;
    }

    public get canDisplayDelete(): boolean {
        return this.deletePopup;
    }

    // #endregion Properties

    // #region Commands

    public navigateToCustomersCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Customers.Table);
    });

    public navigateToEditCustomerCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Customers.Edit.replace(":id", this.model.customer.id));
    });

    public navigateToNewEnquiryCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Enquiries.NewCustomer.replace(":customerId", this.model.customer.id));
    });

    public navigateToEnquiryCommand = new RelayCommand((id: string) => {
        this.history.push(AppUrls.Client.Enquiries.Edit.replace(":id", id));
    });

    public navigateToNewQuoteCommand = new RelayCommand((isQuickQuote: boolean) => {
        const url = AppUrls.Client.Quotes.NewCustomer.replace(":customerId", this.model.customer.id).replace(":isQuickQuote", isQuickQuote.toString());
        this.history.push(url);
    });

    public navigateToQuoteCommand = new RelayCommand((id: string) => {
        this.history.push(AppUrls.Client.Quotes.Edit.replace(":id", id));
    });

    public navigateToProjectCommand = new RelayCommand((id: string) => {
        this.history.push(AppUrls.Client.Projects.Details.replace(":id", id));
    });

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    public deleteModalOpenCommand = new RelayCommand(
        () => (this.deletePopup = true),
        () => this.isAdmin || this.isSeniorAssociate || this.isBusinessSupport,
    );

    public closeDeleteModalCommand = new RelayCommand(() => {
        this.deletePopup = false;
    });

    public deleteCustomerCommand = new RelayCommand(
        () => {
            this.apiClient.sendAsync(new DeleteCustomerEndpoint(this.model.customer.id, this));
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    // #endregion Commands

    // #region Supporting

    public getCustomerContactName = (customerContact: CustomerContact) => {
        return [customerContact.prefixName, customerContact.firstName, customerContact.lastName].filter((item) => !isEmptyOrWhitespace(item)).join(" ");
    };

    public getBusinessContactTypeName = (customerContact: CustomerContact) => {
        return !isEmptyOrWhitespace(customerContact.businessContactTypeName) ? customerContact.businessContactTypeName : "";
    };

    public getCustomerContactNumber1 = (customerContact: CustomerContact) => {
        return !isEmptyOrWhitespace(customerContact.contactNumber1) ? customerContact.contactNumber1 : "";
    };

    public getCustomerContactNumber2 = (customerContact: CustomerContact) => {
        return !isEmptyOrWhitespace(customerContact.contactNumber2) ? customerContact.contactNumber2 : "";
    };

    public getCustomerContactEmailAddress = (customerContact: CustomerContact) => {
        return !isEmptyOrWhitespace(customerContact.emailAddress) ? customerContact.emailAddress : "";
    };

    public getCustomerContactAddress = (customerContact: CustomerContact) => {
        return !customerContact.sameAsCustomerAddress
            ? [customerContact.address1, customerContact.address2, customerContact.address3, customerContact.city, customerContact.postcode]
                  .filter((item) => !isEmptyOrWhitespace(item))
                  .join(", ")
            : "-";
    };

    // Enquiries Below...
    public getEnquiryStatusColor = (id?: string) => {
        return this.getEnquiryStatus(id)?.foregroundColor ?? "#000000";
    };

    public getEnquiryStatusName = (id?: string) => {
        return this.getEnquiryStatus(id)?.name ?? "-";
    };

    public getEnquiryContactName = (enquiry: Enquiry) => {
        return !isEmptyOrWhitespace(enquiry.lastActionLastName) ? `${enquiry.contactFirstName} ${enquiry.contactLastName}` : "-";
    };

    public getEnquiryLastActionUser = (enquiry: Enquiry) => {
        return `${enquiry.lastActionFirstName} ${enquiry.lastActionLastName}`;
    };

    public getEnquiryLastActionDate = (enquiry: Enquiry) => {
        return enquiry.lastActionDate ? formatDate(enquiry.lastActionDate!) : "-";
    };

    // Quotes Below...
    public getQuoteStatusColor = (id?: string) => {
        return this.getQuoteStatus(id)?.foregroundColor ?? "#000000";
    };

    public getQuoteStatusName = (id?: string) => {
        return this.getQuoteStatus(id)?.name ?? "-";
    };

    public getQuoteHours = (quote: Quote) => {
        return quote.hours ? quote.hours : "-";
    };

    public getQuoteValue = (quote: Quote) => {
        return quote.value ? formatCurrency(quote.value) : "-";
    };

    public getQuoteContactName = (quote: Quote) => {
        return `${quote.contactFirstName} ${quote.contactLastName}`;
    };

    public getQuoteLastActionUser = (quote: Quote) => {
        return !isEmptyOrWhitespace(quote.lastActionLastName) ? `${quote.lastActionFirstName} ${quote.lastActionLastName}` : "-";
    };

    public getQuoteLastActionDate = (quote: Quote) => {
        return quote.lastActionDate ? formatDate(quote.lastActionDate!) : "-";
    };

    // Projects Below...
    public getProjectStatusColor = (id?: string) => {
        return this.getProjectStatus(id)?.foregroundColor ?? "#000000";
    };

    public getProjectStatusName = (id?: string) => {
        return this.getProjectStatus(id)?.name ?? "-";
    };

    public getProjectHours = (project: Project) => {
        return project.hours ? project.hours : "-";
    };

    public getProjectValue = (project: Project) => {
        return project.value ? formatCurrency(project.value) : "-";
    };

    public getProjectContactName = (project: Project) => {
        return `${project.contactFirstName} ${project.contactLastName}`;
    };

    public getProjectLastActionUser = (project: Project) => {
        return !isEmptyOrWhitespace(project.lastActionLastName) ? `${project.lastActionFirstName} ${project.lastActionLastName}` : "-";
    };

    public getProjectLastActionDate = (project: Project) => {
        return project.lastActionDate ? formatDate(project.lastActionDate!) : "-";
    };

    // Other...
    private getEnquiryStatus = (id?: string) => {
        return this.lookupStore.enquiryStatuses.find((c: any) => c.id === id);
    };

    private getQuoteStatus = (id?: string) => {
        return this.lookupStore.quoteStatuses.find((c: any) => c.id === id);
    };

    private getProjectStatus = (id?: string) => {
        return this.lookupStore.projectStatuses.find((c: any) => c.id === id);
    };

    // #endregion Supporting
}
