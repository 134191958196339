import { ICalloutContentStyles, ITooltipHostStyles, mergeStyleSets, Modal, PersonaSize, Stack, TooltipHost } from "@fluentui/react";
import { isEmptyOrWhitespace } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import React, { useEffect } from "react";

import { AutoGrid, Box, pxToRem, theme, ThemedComboBox, ThemedDefaultRadioButtons, ThemedEditDate, ThemedEditText, ThemedPersonaComboBox, ThemedText } from "Application";
import { ExclamationIconSVG } from "Assets/ExclamationIconSVG";
import { QuoteViewModel } from "./QuoteViewModel";
import { FormContainerRow, FormSeparator, RatingButton, RatingsContainer, ThemedTextRatingLabel, TitleContainer } from "Views/Shared/SharedComponents";
import { SourceOfEnquiryView } from "../SourceOfEnquiry/SourceOfEnquiryView";
import { ThemedPersona } from "Styles/ThemedPrimitives/Style1/ThemedPersona";

export interface IQuoteViewProps {
    viewModel: QuoteViewModel;
}

const labelStyle = mergeStyleSets({
    root: {
        fontSize: theme.fontStyles.h3.fontSize ?? theme.defaultFontStyle.fontSize,
        fontWeight: theme.fontStyles.h3.fontWeight ?? theme.defaultFontStyle.fontWeight,
        letterSpacing: theme.fontStyles.h3.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
        lineHeight: theme.fontStyles.h3.lineHeight ?? theme.defaultFontStyle.lineHeight,
        textTransform: theme.fontStyles.h3.textTransform ?? theme.defaultFontStyle.textTransform,
    },
});

export const QuoteView: React.FC<IQuoteViewProps> = observer((props) => {
    // #region Code Behind

    const viewModel = props.viewModel;

    useEffect(() => {
        viewModel.initialize();
    }, [viewModel._isLinkedToProject]);

    const toolTipHostStyles: Partial<ITooltipHostStyles> = {
        root: {
            "&&": {
                margin: `auto auto auto ${pxToRem(10)}`,
                maxHeight: pxToRem(25),
                paddingTop: pxToRem(3),
            },
        },
    };

    const calloutStyles: Partial<ICalloutContentStyles> = {
        beak: { background: theme.palette.common.error },
        beakCurtain: { background: theme.palette.common.error },
        calloutMain: { background: theme.palette.common.error },
    };

    const ChoiceGroupStyles = {
        label: {
            display: "inline",
        },
        flexContainer: {
            columnGap: "1em",
            display: "inline-flex",
            flexDirection: "row",
            flexWrap: "wrap",
        },
    };

    // #endregion Code Behind

    const renderConfidenceRatings = () => {
        const renderLabel = () => {
            if (!isEmptyOrWhitespace(viewModel.getError("confidence"))) {
                return (
                    <Stack horizontal>
                        <ThemedTextRatingLabel>CONFIDENCE*</ThemedTextRatingLabel>
                        <TooltipHost
                            id={nanoid()}
                            content={
                                <ThemedText fontStyle={"h7"} color={"white"}>
                                    {viewModel.getError("confidence") as string}
                                </ThemedText>
                            }
                            tooltipProps={{
                                calloutProps: {
                                    gapSpace: -5,
                                    styles: calloutStyles,
                                },
                            }}
                            styles={toolTipHostStyles}
                        >
                            <ExclamationIconSVG backgroundcolor={theme.palette.common.error} color={theme.palette.common.white} />
                        </TooltipHost>
                    </Stack>
                );
            }

            return (
                <Stack horizontal>
                    <ThemedTextRatingLabel>CONFIDENCE*</ThemedTextRatingLabel>
                </Stack>
            );
        };

        return (
            <div>
                {renderLabel()}
                <RatingsContainer>
                    {viewModel.ratings.map((item) => (
                        <RatingButton
                            key={item}
                            className={item <= parseInt(viewModel.getValue("confidence")) ? "active rating-round" : "rating-round"}
                            onClick={() => viewModel.updateConfidenceCommand.execute(item)}
                        />
                    ))}
                </RatingsContainer>
            </div>
        );
    };

    const renderDesirabilityRatings = () => {
        const renderLabel = () => {
            if (!isEmptyOrWhitespace(viewModel.getError("desirability"))) {
                return (
                    <Stack horizontal>
                        <ThemedTextRatingLabel>DESIRABILITY*</ThemedTextRatingLabel>
                        <TooltipHost
                            id={nanoid()}
                            content={
                                <ThemedText fontStyle={"h7"} color={"white"}>
                                    {viewModel.getError("desirability") as string}
                                </ThemedText>
                            }
                            tooltipProps={{
                                calloutProps: {
                                    gapSpace: -5,
                                    styles: calloutStyles,
                                },
                            }}
                            styles={toolTipHostStyles}
                        >
                            <ExclamationIconSVG backgroundcolor={theme.palette.common.error} color={theme.palette.common.white} />
                        </TooltipHost>
                    </Stack>
                );
            }

            return (
                <Stack horizontal>
                    <ThemedTextRatingLabel>DESIRABILITY*</ThemedTextRatingLabel>
                </Stack>
            );
        };

        return (
            <div>
                {renderLabel()}
                <RatingsContainer>
                    {viewModel.ratings.map((item) => (
                        <RatingButton
                            key={item}
                            className={item <= parseInt(viewModel.getValue("desirability")) ? "active purple rating-round" : "rating-round"}
                            onClick={() => viewModel.updateDesirabilityCommand.execute(item)}
                        />
                    ))}
                </RatingsContainer>
            </div>
        );
    };

    return (
        <React.Fragment>
            <FormContainerRow dc={"75%"}>
                <AutoGrid m={"0"} columnGap={"30px"} dc={"1fr 1fr 1fr"}>
                    <ThemedDefaultRadioButtons
                        displayName="Project type"
                        styles={ChoiceGroupStyles}
                        options={viewModel.projectTypeOptions}
                        value={() => viewModel.getValue("projectTypeId")}
                        command={viewModel.updateProjectTypeCommand}
                        validationMessage={() => viewModel.getError("projectTypeId")}
                    />
                    <ThemedPersonaComboBox
                        command={viewModel.updateQuoteLeadCommand}
                        displayName="Quote Lead"
                        labelStyle={labelStyle}
                        options={viewModel.quoteLeads}
                        placeholder="Please select"
                        value={() => viewModel.getValue("quoteLeadId")}
                    />
                    <ThemedEditDate
                        command={viewModel.updateQuoteQuoteDateCommand}
                        displayName="Quote date"
                        size="larger"
                        validationMessage={() => viewModel.getError("quoteDate")}
                        value={() => viewModel.getValue("quoteDate")}
                    />
                </AutoGrid>
            </FormContainerRow>
            <FormContainerRow dc={"75%"}>
                <AutoGrid m={"0"} columnGap={"30px"} dc={"1fr 0.5fr"}>
                    <ThemedEditText
                        command={viewModel.updateTitleCommand}
                        displayName="Job name*"
                        placeholder="Job name"
                        validationMessage={() => viewModel.getError("title")}
                        value={() => viewModel.getValue("title") ?? ""}
                    />
                    <ThemedComboBox
                        displayName="Link to Existing Project"
                        options={viewModel.getProjectsListFormatted}
                        command={viewModel.updateProjectIdCommand}
                        placeholder={"Please select a project (if required)"}
                        value={() => viewModel.getValue("projectId")}
                    />
                </AutoGrid>
            </FormContainerRow>
            {!viewModel.canDisplayForQuickQuote && (
                <FormContainerRow dc={"10% 10% 290px"}>
                    <ThemedEditText
                        command={viewModel.updateLeadInWeeksCommand}
                        displayName="Lead in*"
                        placeholder="No. of weeks"
                        validationMessage={() => viewModel.getError("leadInWeeks")}
                        value={() => viewModel.getValue("leadInWeeks") ?? ""}
                    />
                    <ThemedEditText
                        command={viewModel.updateDurationWeeksCommand}
                        displayName="Duration*"
                        placeholder="No. of weeks"
                        validationMessage={() => viewModel.getError("durationWeeks")}
                        value={() => viewModel.getValue("durationWeeks") ?? ""}
                    />

                    {/* Confidence and Desirability Controls in 2 parts */}
                    <AutoGrid m={"0"} columnGap={"30px"} dc={"1fr 1fr"}>
                        {renderConfidenceRatings()}
                        {renderDesirabilityRatings()}
                    </AutoGrid>
                </FormContainerRow>
            )}

            <FormSeparator />
        </React.Fragment>
    );
});
